'use client';
import React from 'react';
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
} from '@nextui-org/react';
import { Portal } from '@/lib/constants';
import { LanguageSwitcher } from '@/components/LanguageSwitcher';
import { useTranslations } from 'next-intl';
import NextImage from 'next/image';
import CHCLogo from '@/public/static/CHCLogo.svg';

export default function LayoutHeader({ portal, params }: { portal: Portal; params: { locale: string } }) {
  const t = useTranslations('LayoutHeader');
  const navItemLabels = ['Kids', 'Causes', 'About', 'Home', 'Parents', 'Story', 'Donate', 'Library'];
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const basicItems = [
    { label: 'Kids', href: '/kidsLogin' },
    { label: 'Causes', href: '/causes' },
    { label: 'About', href: '/about' },
  ];

  const publicItems = [{ label: 'Home', href: '/' }, { label: 'Parents', href: '/parents' }, ...basicItems];
  const parentsItems = [{ label: 'Home', href: '/parents' }, ...basicItems];
  const adminItems = [...parentsItems, { label: 'Admin', href: '/admin' }];

  const kidsItems = [
    { label: 'Home', href: '/kids/home' },
    { label: 'Story', href: '/kids/story' },
    { label: 'Donate', href: '/kids/donate' },
    { label: 'Library', href: '/kids/library' },
    { label: 'About', href: '/about' },
  ];

  const navItems =
    portal == Portal.Public
      ? publicItems
      : portal == Portal.Admin
        ? adminItems
        : portal == Portal.Parents
          ? parentsItems
          : kidsItems;

  return (
    <Navbar
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      className="bg-old-lace backdrop-blur-none backdrop-saturate-100 shadow sticky top-0 z-20 p-0 m-0"
      maxWidth="full"
    >
      <NavbarContent>
        <NavbarMenuToggle aria-label={isMenuOpen ? 'Close menu' : 'Open menu'} className="md:hidden" />
        <NavbarBrand className="">
          <Link href={`/`} className="mt-3">
            <NextImage src={CHCLogo} width={60} height={60} alt="Chesed Club Logo" />
          </Link>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden md:flex gap-4" justify="center">
        {navItems.map((item, index) => (
          <NavbarItem key={`${item}-${index}`}>
            <Link className="uppercase font-bold font-titillium-web text-black" href={item.href}>
              {t(item.label)}
            </Link>
          </NavbarItem>
        ))}
        <LanguageSwitcher />
      </NavbarContent>
      <NavbarContent justify="end">
        {portal == Portal.Public ? (
          <>
            <NavbarItem>
              <Link
                href={'/parents'}
                className="p-1 border-3 border-black bg-dark-slate-blue min-w-max place-self-center font-titillium-web text-old-lace text-xl text-center font-bold hover:text-black hover:bg-light-steel-blue disabled:opacity-50"
              >
                {t('LogIn')}
              </Link>
            </NavbarItem>
            <NavbarItem>
              <Link
                href={'/signup'}
                className="p-1 border-3 border-black min-w-max place-self-center font-titillium-web text-xl text-center font-bold text-black hover:text-old-lace hover:bg-goldenrod"
              >
                {t('SignUp')}
              </Link>
            </NavbarItem>
          </>
        ) : (
          <NavbarItem>
            <Link
              href={'/logout'}
              className="p-1 border-3 border-black min-w-max place-self-center font-titillium-web text-xl text-center font-bold text-black hover:text-old-lace hover:bg-goldenrod"
            >
              {t('LogOut')}
            </Link>
          </NavbarItem>
        )}
      </NavbarContent>
      <NavbarMenu>
        {navItems.map((item, index) =>
          navItemLabels.includes(item.label) ? (
            <NavbarMenuItem key={`${item}-${index}`}>
              <Link className="w-full uppercase font-bold font-titillium-web text-black" href={item.href} size="lg">
                {t(item.label)}
              </Link>
            </NavbarMenuItem>
          ) : (
            ''
          )
        )}
        <LanguageSwitcher />
      </NavbarMenu>
    </Navbar>
  );
}
